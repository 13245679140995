import { Component } from 'react';
import { connect } from 'react-redux';
import config from '../../../config/config';

const mapStateToProps = state => {
    return {
        pageTitle: state.common.pageTitle
    }
}

class DocTitle extends Component {

    render() {
        let title = config.get('app_name');
        
        if(this.props.pageTitle) {
            title = `${this.props.pageTitle} - ${title}`
        }
        
        document.title = title;
        
        return (null);
    }
}

export default connect(mapStateToProps, null)(DocTitle);