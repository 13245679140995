import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import Loader from "./Loader";
import Logo from "../../../assets/images/PS_Logo_White.png";
// import EngagysLogo from '../../../assets/images/engagys-logo.jpg'

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.account.isLoggedIn,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(push("/account/logout")),
});

class MainLayout extends Component {
  logout = () => {
    this.props.onLogout();
  };

  render() {
    if (!this.props.isLoggedIn) {
      return <Redirect to="/login" />;
    }
    if (_.isEmpty(JSON.parse(window.localStorage.getItem("userData")))) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="app-wrapper">
          <div className="top-section">
            <div className="topbar-left">
              <Link to="/home">
                <img src={Logo} alt="Parcelshield Logo" />
              </Link>
            </div>
            <div className="topbar-right">
              <Link to="#" onClick={this.logout} className="align-center">
                <i className="fa fa-sign-out" aria-hidden="true"></i> Logout
              </Link>
            </div>
          </div>
          <section className="main-wrapper">{this.props.children}</section>
        </div>
        {this.props.loader && <Loader />}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
