import React, { Component } from 'react';

class PageNotFound extends Component {
    render(){
        return (
            <h3 className="page-not-found-wrapper">
                Page Not Found
            </h3>
        )
    }
}

export default PageNotFound;