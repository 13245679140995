import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "../account/Login";
import DefaultLayout from "./layout/DefaultLayout";
import MainLayout from "./layout/MainLayout";
import Home from "./home";
import Details from "../Details";
import PageNotFound from "./layout/PageNotFound";

const authenticateUser = () => {
  // TODO add role based authentication
  return true;
};

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout {...props}>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticateUser() ? (
          <MainLayout {...props}>
            <Component {...props} {...rest} />
          </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/home",
            }}
          />
        )
      }
    />
  );
};
class AppRoute extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/details" component={Details} />

        {/* Account */}
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute component={PageNotFound} />
      </Switch>
    );
  }
}

export default AppRoute;
