import { LOGIN_SUCCESS, LOGOUT } from '../constants/action-types';
import engagysPortalApi from '../services/engagys-api';

const localStorageMiddleware = store => next => action => {
    if (action.type === LOGIN_SUCCESS) {
        let data = action.payload;
        window.localStorage.setItem('userData', JSON.stringify(data));
        engagysPortalApi.setToken(JSON.parse(window.localStorage.getItem('userData')).token);
        next(action);
    } else if (action.type === LOGOUT) {
        window.localStorage.clear();
        engagysPortalApi.setToken(null);
        // window.location.href = '/login';
        next(action);           
    } else {
        next(action);
    }
};

export default localStorageMiddleware;