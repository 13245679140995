import React, { Component } from "react";
import { connect } from "react-redux";
import { CommonActions } from "../../../actions/common";
import { HomeActions } from "../../../actions/home";
import ListTable from "./ListTable";
import _ from "lodash";
import { Breadcrumbs, Typography } from "@material-ui/core";

const mapStateToProps = (state) => {
  return {
    summary: state.home.summary,
    accountDetails: state.home.accountDetails,
    campaignsList: state.home.campaignsList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPageLoad: () => dispatch(CommonActions.homepageLoad()),
  onGetCampaignListAndSummary: () =>
    dispatch(HomeActions.getCampaignListAndSummary()),
  onPauseAllCalls: (payload, accountId) =>
    dispatch(HomeActions.pauseAllCalls(payload, accountId)),
  onCreateCampaign: (payload) => dispatch(HomeActions.createCampaign(payload)),
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      tooltipString: "",
      isPauseAllCalls: false,
      newCampaignName: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let filterCampaignList = nextProps.campaignsList.filter(
      (campaign) => campaign.status === true
    );
    if (filterCampaignList.length >= 1) {
      this.setState({
        isPauseAllCalls: false,
      });
    } else {
      this.setState({
        isPauseAllCalls: true,
      });
    }
  }

  componentDidMount() {
    this.props.onPageLoad();
    this.props.onGetCampaignListAndSummary();
    if (!_.isEmpty(window.localStorage.getItem("accountId"))) {
      window.localStorage.removeItem("accountId");
    }
    if (!_.isEmpty(window.localStorage.getItem("campaignId"))) {
      window.localStorage.removeItem("campaignId");
    }
    if (!_.isEmpty(window.localStorage.getItem("campaignName"))) {
      window.localStorage.removeItem("campaignName");
    }
    if (!_.isEmpty(window.localStorage.getItem("statusId"))) {
      window.localStorage.removeItem("statusId");
    }
    if (!_.isEmpty(window.localStorage.getItem("contactNumber"))) {
      window.localStorage.removeItem("contactNumber");
    }
  }

  pauseAllCalls = () => {
    this.props.onPauseAllCalls(
      {
        status: this.state.isPauseAllCalls,
      },
      this.props.accountDetails.id
    );
  };

  createCampaign = (event) => {
    event.preventDefault();

      const clientAccountCampaignSids = {
        'Alliance R X Walgreens Pharmacy': 'FW4462cb325d613508f9aead9f3ac56ca1',
        'Sterling Specialty Pharmacy': 'FW9818417f89b28dc3bb121f971f2c3b03',
        'Sterling': 'FW93625a89b4e62c08aedb64b03c9749a1',
    };

    const clientAccountCampaignThrottle = {
        'Alliance R X Walgreens Pharmacy': 5,
        'Sterling Specialty Pharmacy': 1,
        'Sterling': 1,
    };

    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const campaignSid = clientAccountCampaignSids[userData.clientAccount.name];
    const campaignThrottle = clientAccountCampaignThrottle[userData.clientAccount.name];

    this.props.onCreateCampaign({
      name: this.state.newCampaignName,
      campaignSID: campaignSid,
      throttle: campaignThrottle,
      ClientAccountId: 1
    })

    this.setState({
      newCampaignName: "",
    });
  };

  handleChange = (event) => {
    this.setState({
      newCampaignName: event.target.value,
    });
  };

  render() {
    return (
      <div className="wrapper">
        <div className="container">
          <div className="breadcrumb-section">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Home</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="content-section">
          <div className="container">
            {/* <div className="form-selection-wrapper">
                    <div className="select-box-wrapper">
                        <select className="select-field">
                            <option>Select Account</option>
                            <option>1</option>
                            <option>1</option>
                            <option>1</option>
                        </select>
                        <span><i className="fa fa-chevron-down" aria-hidden="true"></i></span>
                    </div>
                </div> */}
            <div className="heading-row mar-bottom-20">
              {!_.isEmpty(this.props.accountDetails) && (
                <h2 className="main-heading">
                  {this.props.accountDetails.name}
                </h2>
              )}
              <button
                className="secondary-btn btn"
                onClick={this.pauseAllCalls}
                hidden
              >
                {!this.state.isPauseAllCalls
                  ? "Pause All Calls"
                  : "Play All Calls"}
              </button>
            </div>
            <div className="widget-row mb-4">
              <div className="info-box widget-border-blue">
                <h4
                  className="label-text"
                  title="The maximum combined concurrent calls this account can perform"
                >
                  Account Max Throttle
                </h4>
                {!_.isEmpty(this.props.summary) && (
                  <h1>{this.props.summary.accountMaxThrottle}</h1>
                )}
              </div>
              <div className="info-box widget-border-pink">
                <h4
                  className="label-text"
                  title="The total amount of remaining calls across all campaigns in the displayed account"
                >
                  All Remaining Calls
                </h4>
                {!_.isEmpty(this.props.summary) && (
                  <h1>{this.props.summary.allRemainingCalls}</h1>
                )}
              </div>
              <div className="info-box widget-border-orange">
                <h4
                  className="label-text"
                  title="The total amount of active calls across all campaigns in the displayed account"
                >
                  All Active Calls
                </h4>
                {!_.isEmpty(this.props.summary) && (
                  <h1>{this.props.summary.allActiveCalls}</h1>
                )}
              </div>
              <div className="info-box widget-border-green">
                <h4
                  className="label-text"
                  title="The total amount of active transfers across all campaigns in the displayed account"
                >
                  All Active Transfers
                </h4>
                {!_.isEmpty(this.props.summary) && (
                  <h1>{this.props.summary.allActiveTransfers}</h1>
                )}
              </div>
            </div>

            <ListTable />

            <div className="mb-4">
              <h4>Create Campaign</h4>
              <form onSubmit={this.createCampaign}>
                <div className="form-row align-items-center">
                  <div className="col-auto">
                    <input
                      type="text"
                      className="form-control"
                      id="campaignName"
                      placeholder="Campaign Name"
                      value={this.state.newCampaignName}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-auto">
                    <button type="submit" className="btn primary-btn">
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
