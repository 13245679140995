import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from './Loader';

const mapStateToProps = (state) => {
    return {
        loader : state.common.loader
    };
}

class DefaultLayout extends Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    {this.props.children}
                </div>
                {this.props.loader && <Loader /> }
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps)(DefaultLayout);