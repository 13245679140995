import { Field, Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AccountActions } from '../../actions/account';
import { CommonActions } from '../../actions/common';
import Logo from "../../assets/images/PS_Logo_Color.png";

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        alert: state.account.alert,
        userInfo: state.account.userInfo
    };
}

const mapDispatchToProps = (dispatch) => ({
    onPageLoad: () =>
        dispatch(CommonActions.loginPageLoad()),
    onAuthenticate: (values) =>
        dispatch(AccountActions.login(values))
})

class Login extends Component {
    componentDidMount() {
        this.props.onPageLoad();
    }

    render() {
        const query = new URLSearchParams(this.props.location.search)
        const redirectPath = query.get('redirectTo');
        
        if (this.props.isLoggedIn) {
            if (redirectPath) {
                return (
                    <Redirect to={redirectPath} />
                )
            }
            return (
                <Redirect to="/home" />
            )
        }
        return (
            <div className="wrapper">
                <div className="login-page-wrapper">
                    <div className="login-main-section">
                    { this.props.alert.type === 'error' &&
                            <p className="error-wrapper">{this.props.alert.message.message}</p>
                        }
                        <Formik
                            validateOnChange={false}
                            initialValues={{
                                userName: "",
                                password: ""
                            }}
                            validate={values => {
                                // email validation
                                let errors = {};
                                if (!values.userName) {
                                    errors.userName = 'Username is required.';
                                } 
                                // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(values.email)) {
                                //     errors.email = 'Please enter a valid email address.';
                                // }

                                // password validation
                                if (!values.password) {
                                    errors.password = 'Password is required.';
                                }

                                return errors;
                            }}
                            onSubmit={(values, actions) => {
                                this.props.onAuthenticate(values);

                                // setTimeout(function () {
                                //     actions.resetForm({
                                //         userName: values.userName,
                                //         password: ""
                                //     })
                                // }, 500);
                            }}
                            render={(props) => {
                                return (
                                    <form className="login100-form validate-form" autoComplete="off">
                                        <img src={Logo} alt='ParcelShield Logo' className='login-form-title' />
                                
                                        <div className="wrap-input100 validate-input">
                                            <Field type="text" name="userName" className="input100" placeholder="Username" />
                                        </div>
                                        <div className="error-wrapper">
                                                {props.errors.userName ? <span className="error">{props.errors.userName}</span> : ''}
                                        </div>
                                        <div className="wrap-input100 validate-input">
                                            <Field type="password" name="password" className="input100" placeholder="Password" />
                                        </div>
                                        <div className="error-wrapper">
                                                {props.errors.password ? <span className="error">{props.errors.password}</span> : ''}
                                        </div>
                                    
                                        <div className="container-login-form-btn">
                                            <button type="submit" className="login-form-btn" onClick={props.handleSubmit}>Log in</button> 
                                        </div>
                                    </form>
                                )
                            }}
                        />
                    </div>
                    </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
