import React, { Component } from "react";
import { connect } from "react-redux";
import DocTitle from "./layout/DocTitle";
import AppRoute from "./Route";
import { APP_LOAD } from "../../constants/action-types";
import _ from "lodash";
import { AccountActions } from "../../actions/account";
import engagysPortalApi from "../../services/engagys-api";
import { Redirect } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.account.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: APP_LOAD, payload }),
  onLogout: () => dispatch(AccountActions.logout()),
});

class App extends Component {
  UNSAFE_componentWillMount() {
    this.initUserSession();
  }

  initUserSession() {
    let token;
    if (!_.isEmpty(JSON.parse(window.localStorage.getItem("userData")))) {
      token = JSON.parse(window.localStorage.getItem("userData")).token;
    }

    let payload = {
      isLoggedIn: false,
    };

    if (token !== undefined) {
      engagysPortalApi.setToken(token);
      payload.isLoggedIn = true;
    }

    this.props.onLoad(payload);
  }

  render() {
    if (this.props.location.pathname === "/account/logout") {
      this.props.onLogout();
    }

    if (window.location.pathname === "/") {
      return <Redirect to="/home" />;
    }

    return (
      <React.Fragment>
        <DocTitle />
        <AppRoute />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

(function (w) {
  w.URLSearchParams =
    w.URLSearchParams ||
    function (searchString) {
      var self = this;
      self.searchString = searchString;
      self.get = function (name) {
        var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
          self.searchString
        );
        if (results == null) {
          return null;
        } else {
          return decodeURI(results[1]) || 0;
        }
      };
    };
})(window);
