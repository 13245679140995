import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { HomeActions } from "../../../actions/home";
import TablePagination from "@material-ui/core/TablePagination";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
const mapStateToProps = (state) => {
  return {
    campaignsList: state.home.campaignsList,
    accountDetails: state.home.accountDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRedirect: () => dispatch(HomeActions.redirect()),
  onChangeCampaignStatus: (data, campaignId, page) =>
    dispatch(HomeActions.changeCampaignStatus(data, campaignId, page)),
});

class ListTable extends Component {
  state = {
    page_number: 0,
    page_size: 10,
    sort: false,
    dNumber: false,
    rNumber: false,
    throttle: false,
    rMembers: false,
    aCalls: false,
    aTransfers: false,
    campaignsList: [],
  };
  setValues = (
    campaignId,
    campaignName,
    statusId,
    callTransferDefaultValue
  ) => {
    this.props.onRedirect();
    window.localStorage.setItem("accountId", this.props.accountDetails.id);
    window.localStorage.setItem("campaignId", campaignId);
    window.localStorage.setItem("campaignName", campaignName);
    window.localStorage.setItem("statusId", statusId);
    window.localStorage.setItem("contactNumber", callTransferDefaultValue);
  };

  changeStatus = (status, campaignId) => {
    let data = {
      status: status,
    };
    this.props.onChangeCampaignStatus(data, campaignId, "home");
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page_number: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page_size: parseInt(event.target.value, 10),
      page_number: 0,
    });
  };
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      !_.isEmpty(nextProps.campaignsList) &&
      this.props.campaignsList !== nextProps.campaignsList
    ) {
      this.setState({
        campaignsList: nextProps.campaignsList.sort((a, b) =>
          a.campaignName.toLowerCase() < b.campaignName.toLowerCase()
            ? -1
            : a.campaignName.toLowerCase() > b.campaignName.toLowerCase()
            ? 1
            : 0
        ),
      });
    }
  };
  sortNameHandler = (flag) => {
    this.setState({ sort: flag });
    if (flag) {
      this.state.campaignsList
        .sort((a, b) =>
          a.campaignName.toLowerCase() < b.campaignName.toLowerCase()
            ? -1
            : a.campaignName.toLowerCase() > b.campaignName.toLowerCase()
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.campaignsList.sort((a, b) =>
        a.campaignName.toLowerCase() < b.campaignName.toLowerCase()
          ? -1
          : a.campaignName.toLowerCase() > b.campaignName.toLowerCase()
          ? 1
          : 0
      );
    }
  };
  sortDnumberHandler = (flag) => {
    this.setState({ dNumber: flag });
    if (flag) {
      this.state.campaignsList
        .sort((a, b) =>
          a.displayedNumber < b.displayedNumber
            ? -1
            : a.displayedNumber > b.displayedNumber
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.campaignsList.sort((a, b) =>
        a.displayedNumber < b.displayedNumber
          ? -1
          : a.displayedNumber > b.displayedNumber
          ? 1
          : 0
      );
    }
  };
  sortRnumberHandler = (flag) => {
    this.setState({ rNumber: flag });
    if (flag) {
      this.state.campaignsList
        .sort((a, b) =>
          a.returnNumber < b.returnNumber
            ? -1
            : a.returnNumber > b.returnNumber
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.campaignsList.sort((a, b) =>
        a.returnNumber < b.returnNumber
          ? -1
          : a.returnNumber > b.returnNumber
          ? 1
          : 0
      );
    }
  };
  sortThrottleHandler = (flag) => {
    this.setState({ throttle: flag });
    if (flag) {
      this.state.campaignsList
        .sort((a, b) =>
          a.throttle < b.throttle ? -1 : a.throttle > b.throttle ? 1 : 0
        )
        .reverse();
    } else {
      this.state.campaignsList.sort((a, b) =>
        a.throttle < b.throttle ? -1 : a.throttle > b.throttle ? 1 : 0
      );
    }
  };
  sortRmemberHandler = (flag) => {
    this.setState({ rMembers: flag });
    if (flag) {
      this.state.campaignsList
        .sort((a, b) =>
          a.remainingMembers < b.remainingMembers
            ? -1
            : a.remainingMembers > b.remainingMembers
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.campaignsList.sort((a, b) =>
        a.remainingMembers < b.remainingMembers
          ? -1
          : a.remainingMembers > b.remainingMembers
          ? 1
          : 0
      );
    }
  };
  sortAcallHandler = (flag) => {
    this.setState({ aCalls: flag });
    if (flag) {
      this.state.campaignsList
        .sort((a, b) =>
          a.activeCalls < b.activeCalls
            ? -1
            : a.activeCalls > b.activeCalls
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.campaignsList.sort((a, b) =>
        a.activeCalls < b.activeCalls
          ? -1
          : a.activeCalls > b.activeCalls
          ? 1
          : 0
      );
    }
  };
  sortAtransferHandler = (flag) => {
    this.setState({ aTransfers: flag });
    if (flag) {
      this.state.campaignsList
        .sort((a, b) =>
          a.activeTransfers < b.activeTransfers
            ? -1
            : a.activeTransfers > b.activeTransfers
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.campaignsList.sort((a, b) =>
        a.activeTransfers < b.activeTransfers
          ? -1
          : a.activeTransfers > b.activeTransfers
          ? 1
          : 0
      );
    }
  };
  render() {
    const campaignsList = this.state.campaignsList.slice(
      (this.state.page_number + 1 - 1) * this.state.page_size,
      (this.state.page_number + 1) * this.state.page_size
    );
    const count = this.state.campaignsList.length;
    console.log(campaignsList);
    return (
      <div className="table-outter mb-4">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th
                className="label-text"
                title="The name of the corresponding Campaign in Twilio"
              >
                Campaign Name{" "}
                <span>
                  {this.state.sort ? (
                    <ArrowDropDownIcon
                      onClick={() => this.sortNameHandler(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      onClick={() => this.sortNameHandler(true)}
                    />
                  )}
                </span>
              </th>
              <th
                className="label-text"
                title="The displayed number on the Caller ID"
              >
                Displayed Number{" "}
                <span>
                  {this.state.dNumber ? (
                    <ArrowDropDownIcon
                      onClick={() => this.sortDnumberHandler(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      onClick={() => this.sortDnumberHandler(true)}
                    />
                  )}
                </span>
              </th>
              <th
                className="label-text"
                title="The inbound number to the Twilio campaign"
              >
                Return Number{" "}
                <span>
                  {this.state.rNumber ? (
                    <ArrowDropDownIcon
                      onClick={() => this.sortRnumberHandler(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      onClick={() => this.sortRnumberHandler(true)}
                    />
                  )}
                </span>
              </th>
              <th
                className="label-text"
                title="The current amount of outbound calls that can be performed concurrently on this campaign"
              >
                Throttle{" "}
                <span>
                  {this.state.throttle ? (
                    <ArrowDropDownIcon
                      onClick={() => this.sortThrottleHandler(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      onClick={() => this.sortThrottleHandler(true)}
                    />
                  )}
                </span>
              </th>
              <th
                className="label-text"
                title="The remaining members to be called who have not completed the call or exhausted all attempts"
              >
                Remaining Members{" "}
                <span>
                  {this.state.rMembers ? (
                    <ArrowDropDownIcon
                      onClick={() => this.sortRmemberHandler(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      onClick={() => this.sortRmemberHandler(true)}
                    />
                  )}
                </span>
              </th>
              <th
                className="label-text"
                title="The number of active calls on this campaign"
              >
                Active Calls{" "}
                <span>
                  {this.state.aCalls ? (
                    <ArrowDropDownIcon
                      onClick={() => this.sortAcallHandler(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      onClick={() => this.sortAcallHandler(true)}
                    />
                  )}
                </span>
              </th>
              <th
                className="label-text"
                title="The number of active transfers on this campaign"
              >
                Active Transfers{" "}
                <span>
                  {this.state.aTransfers ? (
                    <ArrowDropDownIcon
                      onClick={() => this.sortAtransferHandler(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      onClick={() => this.sortAtransferHandler(true)}
                    />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(campaignsList) &&
              campaignsList.map((item) => (
                <tr key={item.id}>
                  {item.status === 0 && (
                    <td
                      className="hover-col"
                      title="Click to play"
                      onClick={this.changeStatus.bind(this, true, item.id)}
                    >
                      <i className="fa fa-play" aria-hidden="true"></i>
                    </td>
                  )}
                  {item.status === 1 && (
                    <td
                      className="hover-col"
                      title="Click to pause"
                      onClick={this.changeStatus.bind(this, false, item.id)}
                    >
                      <i className="fa fa-pause" aria-hidden="true"></i>
                    </td>
                  )}
                  {item.status === 2 && (
                    <td className="label-text" title="Campaign Closed">
                      <i
                        className="fa fa-ban"
                        aria-hidden="true"
                        style={{ color: "red" }}
                      ></i>
                    </td>
                  )}
                  <td
                    className="text-link"
                    onClick={this.setValues.bind(
                      this,
                      item.id,
                      item.campaignName,
                      item.status,
                      item.callTransferDefaultValue
                    )}
                  >
                    {" "}
                    {item.campaignName}
                  </td>
                  <td>{item.displayedNumber}</td>
                  <td>{item.returnNumber}</td>
                  <td className="text-center">{item.throttle}</td>
                  <td className="text-center">{item.remainingMembers}</td>
                  <td className="text-center">{item.activeCalls}</td>
                  <td className="text-center">{item.activeTransfers}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <TablePagination
          component="div"
          count={count}
          page={this.state.page_number}
          onPageChange={this.handleChangePage}
          rowsPerPage={this.state.page_size}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTable);
