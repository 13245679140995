import React, { Component } from "react";
import { connect } from "react-redux";
import { CommonActions } from "../../actions/common";
import { DetailsActions } from "../../actions/details";
import _ from "lodash";
import { HomeActions } from "../../actions/home";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Breadcrumbs,
  Link,
  Switch,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Modal from "react-bootstrap/Modal";
import { withStyles } from "@material-ui/core/styles";
// const scheduleData = [
//   { day: "Monday", flag: true, startTime: "09:00", endTime: "16:00" },
//   { day: "Tuesday", flag: true, startTime: "09:00", endTime: "16:00" },
//   { day: "Wednesday", flag: true, startTime: "09:00", endTime: "16:00" },
//   { day: "Thursday", flag: true, startTime: "09:00", endTime: "16:00" },
//   { day: "Friday", flag: true, startTime: "09:00", endTime: "16:00" },
//   { day: "Saturday", flag: false, startTime: "09:00", endTime: "16:00" },
//   { day: "Sunday", flag: false, startTime: "09:00", endTime: "16:00" },
// ];
const styles = withStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#009da9",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#009da9",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const mapStateToProps = (state) => {
  return {
    fileImportData: state.details.fileImportData,
    campaignStatus: state.home.campaignStatus,
    formSaveData: state.details.formSaveData,
    fileImportError: state.details.fileImportError,
    campaignDetails: state.details.campaignDetails,
    downloadData: state.details.downloadData,
    uploadHistory: state.details.uploadHistory,
    fileList: state.details.fileList,
    removedMembers: state.details.removedMembers,
    closeCampaign: state.details.closeCampaign,
    campaignSchedule: state.details.campaignSchedule,
    postCampaign: state.details.postCampaign,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDetailsPageLoad: () => dispatch(CommonActions.detailsPageLoad()),
  onFileImport: (data, accountId, campaignId) =>
    dispatch(DetailsActions.fileImport(data, accountId, campaignId)),
  onFormDataSave: (data, campaignId, campaignName) =>
    dispatch(DetailsActions.formDataSave(data, campaignId, campaignName)),
  onResetFileUploadMessage: () =>
    dispatch(DetailsActions.resetFileUploadMessage()),
  onChangeCampaignStatus: (data, campaignId, page) =>
    dispatch(HomeActions.changeCampaignStatus(data, campaignId, page)),
  onResetCampaignStatus: () => dispatch(HomeActions.resetCampaignStatus()),
  onResetFormDataSave: () => dispatch(DetailsActions.resetFormDataSave()),
  onRemoveMemebrs: (campaignId) =>
    dispatch(DetailsActions.removeMembers(campaignId)),
  onResetFileImportError: () => dispatch(DetailsActions.resetFileImportError()),
  onGetCampaignDetails: (campaignId) =>
    dispatch(DetailsActions.getCampaignDetails(campaignId)),
  onGetUploadHistory: (campaignId) =>
    dispatch(DetailsActions.getUploadHistory(campaignId)),
  onFileListImport: (campaignId, type) =>
    dispatch(DetailsActions.fileListImport(campaignId, type)),
  onRemoveQueue: (campaignId) =>
    dispatch(DetailsActions.removeQueuedMembers(campaignId)),
  onCloseCampaign: (campaignId) =>
    dispatch(DetailsActions.closeCampaign(campaignId)),
  onCampaignSchedule: (campaignId) =>
    dispatch(DetailsActions.campaignSchedule(campaignId)),
  onPostCampaign: (data) => dispatch(DetailsActions.postCampaign(data)),
});

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactNumber: this.getContactNumber(),
      contactNumberError: "",
      successMessageFileUpload: "",
      toggleButon: null,
      fileTypeError: false,
      successMessage: "",
      fileImportError: false,
      page_number: 0,
      page_size: 10,
      sort: false,
      uploadedBy: false,
      totRecCount: false,
      valRecCount: false,
      rejRecCount: false,
      uploadedAt: false,
      uploadHistory: [],
      modal: false,
      modalType: null,
      schedule: [],
      disabled: true,
    };
  }
  handleOpen = (type) => {
    if (type === "close") {
      this.setState({ modal: true, modalType: type });
    } else if (type === "remove") {
      this.setState({ modal: true, modalType: type });
    }
  };
  handleClose = () => {
    this.setState({ modal: false });
  };
  componentDidMount() {
    this.props.onDetailsPageLoad();
    this.props.onResetFileUploadMessage();
    this.props.onResetCampaignStatus();
    this.props.onResetFormDataSave();
    this.props.onResetFileImportError();

    this.loadData();
    this.interval = setInterval(this.loadData, 30000);
  }

  loadData = () => {
    if (this.state.disabled) {
      this.props.onCampaignSchedule(window.localStorage.getItem("campaignId"));
    }
    this.props.onGetCampaignDetails(window.localStorage.getItem("campaignId"));
    this.props.onGetUploadHistory(window.localStorage.getItem("campaignId"));
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getContactNumber = () => {
    let contactNumber;
    if (!_.isEmpty(window.localStorage.getItem("contactNumber"))) {
      contactNumber = window.localStorage.getItem("contactNumber");
    } else {
      contactNumber = "";
    }
    return contactNumber;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.fileImportData)) {
      if (nextProps.fileImportData.message === "File upload was successful.") {
        this.setState({
          successMessageFileUpload: "File uploaded Successfully!",
        });
        setTimeout(() => {
          this.setState({
            successMessageFileUpload: "",
          });
        }, 5000);
      } else if (
        this.props.fileImportData !== nextProps.fileImportData &&
        nextProps.fileImportData &&
        nextProps.fileImportData.data === 400
      ) {
        this.setState({
          fileImportError: true,
        });
        toast.error("File upload error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    if (!_.isEmpty(nextProps.formSaveData)) {
      if (nextProps.formSaveData.message === "Successful.") {
        this.setState({
          successMessage: "Success!",
        });
        setTimeout(() => {
          this.setState({
            successMessage: "",
          });
        }, 5000);
      }
    }

    if (nextProps.campaignStatus === null) {
      if (window.localStorage.getItem("statusId") === "0") {
        this.setState({
          toggleButon: 0,
        });
      } else if (window.localStorage.getItem("statusId") === "1") {
        this.setState({
          toggleButon: 1,
        });
      } else if (window.localStorage.getItem("statusId") === "2") {
        this.setState({
          toggleButon: 2,
        });
      }
    } else if (nextProps.campaignStatus === 0) {
      this.setState({
        toggleButon: 0,
      });
    } else if (nextProps.campaignStatus === 1) {
      this.setState({
        toggleButon: 1,
      });
    } else if (nextProps.campaignStatus === 2) {
      this.setState({
        toggleButon: 2,
      });
    }

    if (!_.isEmpty(nextProps.fileImportError)) {
      this.setState({
        fileImportError: true,
      });
      setTimeout(() => {
        this.setState({
          fileImportError: false,
        });
      }, 5000);
    }
    if (
      !_.isEmpty(nextProps.fileList) &&
      this.props.fileList !== nextProps.fileList
    ) {
      const link = nextProps.fileList && nextProps.fileList.filepath;
      if (link.length > 0) {
        let a = document.createElement("a");
        a.style.display = "none";
        a.href = link;
        a.download = link;
        document.body.appendChild(a);

        a.click();
        window.URL.revokeObjectURL(link);
      } else {
        toast.error("File does not exist!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (
      !_.isEmpty(nextProps.uploadHistory) &&
      this.props.uploadHistory !== nextProps.uploadHistory
    ) {
      this.setState({
        uploadHistory: nextProps.uploadHistory
          .sort((a, b) =>
            a.uploadedDateTime < b.uploadedDateTime
              ? -1
              : a.uploadedDateTime > b.uploadedDateTime
              ? 1
              : 0
          )
          .reverse(),
      });
    }
    if (
      this.props.closeCampaign !== nextProps.closeCampaign &&
      nextProps.closeCampaign &&
      nextProps.closeCampaign.data === 200
    ) {
      window.localStorage.setItem("statusId", 2);
      this.setState({
        toggleButon: 2,
      });
    }
    if (
      !_.isEmpty(nextProps.campaignSchedule) &&
      this.props.campaignSchedule !== nextProps.campaignSchedule
    ) {
      this.setState({ schedule: nextProps.campaignSchedule });
    }
    if (
      !_.isEmpty(nextProps.postCampaign) &&
      this.props.postCampaign !== nextProps.postCampaign &&
      nextProps.postCampaign &&
      nextProps.postCampaign.message &&
      nextProps.postCampaign.message === "Successful."
    ) {
      toast.success("Success", {
        position: "bottom-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      this.props.onCampaignSchedule(window.localStorage.getItem("campaignId"));
      this.setState({ disabled: true });
    }
  }

  setChosenFileName = (file) => {
    let data = {
      media: file,
      fileName: file !== undefined ? file.name : "",
      mimeType: file !== undefined ? file.type : "",
      dimension: file !== undefined ? file.size : "",
    };
    if (file !== undefined) {
      let extension = file.name.slice(file.name.lastIndexOf(".") + 1);
      if (extension === "csv") {
        this.props.onFileImport(
          data,
          window.localStorage.getItem("accountId"),
          window.localStorage.getItem("campaignId")
        );
        this.setState({
          fileTypeError: false,
        });
      } else {
        this.setState({
          fileTypeError: true,
        });
        setTimeout(() => {
          this.setState({
            fileTypeError: false,
          });
        }, 5000);
      }
    }
  };
  handlePostCampaign = () => {
    const data = {
      campaignSchedules: this.state.schedule,
    };
    this.props.onPostCampaign(data);
  };
  handleRemoveQueuedMembers = () => {
    this.props.onRemoveQueue(window.localStorage.getItem("campaignId"));
  };
  handleCloseCampaign = () => {
    this.props.onCloseCampaign(window.localStorage.getItem("campaignId"));
  };
  onSubmit = () => {
    let data = {
      campaignId: this.campaignId,
    };

    this.props.onFormDataSave(
      data,
      window.localStorage.getItem("campaignId"),
      window.localStorage.getItem("campaignName")
    );
  };
  actualFileHandler = (campaignFileId) => {
    this.props.onFileListImport(campaignFileId, "actual");
  };
  errorFileHandler = (campaignFileId) => {
    this.props.onFileListImport(campaignFileId, "error");
  };
  onContactNameChange = (value) => {
    let regexp = /^\d+(\.\d{1,2})?$/;
    let result = regexp.test(value);

    let errorMessage;
    if (!result && String(value).length !== 0) {
      errorMessage = "Phone number can't be of this type.";
    } else if (
      result &&
      String(value).length !== 0 &&
      String(value).length > 10
    ) {
      errorMessage = "Phone number can't be greater than 10 digits.";
    } else if (
      result &&
      String(value).length !== 0 &&
      String(value).length < 10
    ) {
      errorMessage = "Phone number can't be less than 10 digits.";
    } else {
      errorMessage = "";
    }

    this.setState({
      contactNumber: String(value),
      contactNumberError: errorMessage,
    });
  };

  onFileClick = (event) => {
    event.target.value = "";
  };

  changeStatus = (status, value) => {
    let data = {
      status: status,
    };
    this.props.onChangeCampaignStatus(
      data,
      window.localStorage.getItem("campaignId"),
      "details"
    );
    window.localStorage.setItem("statusId", value);
    this.setState({
      toggleButon: value,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page_number: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page_size: parseInt(event.target.value, 10),
      page_number: 0,
    });
  };
  handleChangeToggle = (i) => {
    const scheduleData = this.props.campaignSchedule;
    scheduleData[i] = {
      ...scheduleData[i],
      status: !scheduleData[i].status === false ? 0 : 1,
    };
    this.setState({
      schedule: scheduleData,
      disabled: false,
    });
  };
  handleChangeStartTime = (i, startTime) => {
    const scheduleData = this.props.campaignSchedule;
    scheduleData[i] = {
      ...scheduleData[i],
      startTime: startTime,
    };
    this.setState({
      schedule: scheduleData,
      disabled: false,
    });
  };
  handleChangeEndTime = (i, endTime) => {
    const scheduleData = this.props.campaignSchedule;
    scheduleData[i] = {
      ...scheduleData[i],
      endTime: endTime,
    };
    this.setState({
      schedule: scheduleData,
      disabled: false,
    });
  };
  sortHandler = (flag) => {
    this.setState({ sort: flag });
    if (flag) {
      this.state.uploadHistory
        .sort((a, b) =>
          a.originalFileName.toLowerCase() < b.originalFileName.toLowerCase()
            ? -1
            : a.originalFileName.toLowerCase() >
              b.originalFileName.toLowerCase()
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.uploadHistory.sort((a, b) =>
        a.originalFileName.toLowerCase() < b.originalFileName.toLowerCase()
          ? -1
          : a.originalFileName.toLowerCase() > b.originalFileName.toLowerCase()
          ? 1
          : 0
      );
    }
  };
  sortUploadedByHandler = (flag) => {
    this.setState({ uploadedBy: flag });
    if (flag) {
      this.state.uploadHistory
        .sort((a, b) =>
          a.uploadedBy.toLowerCase() < b.uploadedBy.toLowerCase()
            ? -1
            : a.uploadedBy.toLowerCase() > b.uploadedBy.toLowerCase()
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.uploadHistory.sort((a, b) =>
        a.uploadedBy.toLowerCase() < b.uploadedBy.toLowerCase()
          ? -1
          : a.uploadedBy.toLowerCase() > b.uploadedBy.toLowerCase()
          ? 1
          : 0
      );
    }
  };
  sortTotalRecHandler = (flag) => {
    this.setState({ totRecCount: flag });
    if (flag) {
      this.state.uploadHistory
        .sort((a, b) =>
          a.totalRecordCount < b.totalRecordCount
            ? -1
            : a.totalRecordCount > b.totalRecordCount
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.uploadHistory.sort((a, b) =>
        a.totalRecordCount < b.totalRecordCount
          ? -1
          : a.totalRecordCount > b.totalRecordCount
          ? 1
          : 0
      );
    }
  };
  sortValidRecHandler = (flag) => {
    this.setState({ valRecCount: flag });
    if (flag) {
      this.state.uploadHistory
        .sort((a, b) =>
          a.validRecordCount < b.validRecordCount
            ? -1
            : a.validRecordCount > b.validRecordCount
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.uploadHistory.sort((a, b) =>
        a.validRecordCount < b.validRecordCount
          ? -1
          : a.validRecordCount > b.validRecordCount
          ? 1
          : 0
      );
    }
  };
  sortRejectedRecHandler = (flag) => {
    this.setState({ rejRecCount: flag });
    if (flag) {
      this.state.uploadHistory
        .sort((a, b) =>
          a.rejectedRecordCount < b.rejectedRecordCount
            ? -1
            : a.rejectedRecordCount > b.rejectedRecordCount
            ? 1
            : 0
        )
        .reverse();
    } else {
      this.state.uploadHistory.sort((a, b) =>
        a.rejectedRecordCount < b.rejectedRecordCount
          ? -1
          : a.rejectedRecordCount > b.rejectedRecordCount
          ? 1
          : 0
      );
    }
  };
  sortUploadedAtHandler = (flag) => {
    this.setState({ uploadedAt: flag });
    if (flag) {
      this.state.uploadHistory.sort((a, b) =>
        a.uploadedDateTime < b.uploadedDateTime
          ? -1
          : a.uploadedDateTime > b.uploadedDateTime
          ? 1
          : 0
      );
    } else {
      this.state.uploadHistory
        .sort((a, b) =>
          a.uploadedDateTime < b.uploadedDateTime
            ? -1
            : a.uploadedDateTime > b.uploadedDateTime
            ? 1
            : 0
        )
        .reverse();
    }
  };
  // removeMembers = () => {
  //   this.props.onRemoveMemebrs(window.localStorage.getItem('campaignId'));
  // }

  render() {
    const uploadHistory = this.state.uploadHistory.slice(
      (this.state.page_number + 1 - 1) * this.state.page_size,
      (this.state.page_number + 1) * this.state.page_size
    );
    const count = this.props.uploadHistory.length;
    return (
      <div className="wrapper">
        <div className="container">
          <div className="breadcrumb-section">
            <Breadcrumbs separator={">"} aria-label="breadcrumb">
              <Link color="inherit" href="/">
                Home
              </Link>
              <Typography color="textPrimary">Details</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="content-section">
          <div className="container">
            <div className="heading-row">
              <h2 className="main-heading">
                {this.props.campaignDetails.campaignName ?? "Call Controls"}
              </h2>
            </div>
            <p className="success-file-upload">
              {this.state.successMessageFileUpload}
            </p>

            <p className="success">{this.state.successMessage}</p>
            <div className="call-details-wrap">
              <div className="call-details-left">
                {(this.props.campaignDetails.membersLoaded ||
                  this.state.toggleButon === 2) && (
                  <div className="call-btns">
                    {this.state.toggleButon === 0 && (
                      <i
                        className="fa fa-play play-btn"
                        title="Click to play"
                        onClick={this.changeStatus.bind(this, true, 1)}
                        aria-hidden="true"
                      ></i>
                    )}
                    {this.state.toggleButon === 1 && (
                      <i
                        className="fa fa-pause pause-btn"
                        title="Click to pause"
                        onClick={this.changeStatus.bind(this, false, 0)}
                        aria-hidden="true"
                      ></i>
                    )}
                    {this.state.toggleButon === 2 && (
                      <i
                        className="fa fa-ban label-text"
                        aria-hidden="true"
                        title="Campaign Closed"
                        style={{ color: "red" }}
                      ></i>
                    )}
                  </div>
                )}
                {this.state.toggleButon !== 2 && (
                  <div className="call-action-btns">
                    <div className="file-input-wrapper">
                      <button className="secondary-btn btn mar-r-20">
                        Upload Members
                      </button>
                      <input
                        type="file"
                        className="secondary-btn btn mar-r-20"
                        accept=".csv"
                        onChange={(event) =>
                          this.setChosenFileName(event.currentTarget.files[0])
                        }
                        onClick={this.onFileClick}
                      />
                    </div>

                    {this.state.fileTypeError && (
                      <p className="error-wrapper file-format-error">
                        Wrong file format! You can upload only .csv files.
                      </p>
                    )}
                    {this.state.fileImportError && (
                      <p className="error-wrapper file-format-error">
                        {this.props.fileImportError.message}
                      </p>
                    )}
                  </div>
                )}
                {this.state.toggleButon !== 2 && (
                  <React.Fragment>
                    <button
                      className="btn-danger btn mar-r-20 col-md-3"
                      onClick={() => this.handleOpen("close")}
                    >
                      Close Campaign
                    </button>
                    <button
                      className="btn-secondary btn mar-r-20 col-md-4"
                      onClick={() => this.handleOpen("remove")}
                    >
                      Remove Queued Members
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className="heading-row">
              <h2 className="main-heading">Performance Metrics</h2>
              {this.props.campaignDetails.membersLoaded && (
                <button
                  className="primary-btn btn margin-bottom"
                  onClick={this.onSubmit}
                >
                  Download
                </button>
              )}
            </div>

            <div className="matrics-outter ">
              <div className="matrics-wrap">
                <div className="col-4">
                  <table className="table no-border">
                    <thead>
                      <tr>
                        <th colSpan="3">Campaign Overview</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="label-text"
                          title="Total number of members in the current campaign"
                        >
                          Members Loaded{" "}
                        </td>
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.membersLoaded
                                ? this.props.campaignDetails.membersLoaded
                                : ""}
                            </div>
                          </td>
                        )}
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          className="label-text"
                          title="Total number of members called"
                        >
                          Members Called{" "}
                        </td>
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.membersCalled
                                ? this.props.campaignDetails.membersCalled
                                : ""}
                            </div>
                          </td>
                        )}
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.membersCalledInPercent
                                ? this.props.campaignDetails
                                    .membersCalledInPercent
                                : ""}
                            </div>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td
                          className="label-text"
                          title="Total number of members where call has not been attempted"
                        >
                          Members Remaining{" "}
                        </td>
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.membersRemaining
                                ? this.props.campaignDetails.membersRemaining
                                : ""}
                            </div>
                          </td>
                        )}
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails
                                .membersRemainingInPercent
                                ? this.props.campaignDetails
                                    .membersRemainingInPercent
                                : ""}
                            </div>{" "}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Modal
                  show={this.state.modal}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  onHide={this.handleClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      {this.state.modalType === "remove"
                        ? "Remove Queued Members"
                        : "Close Campaign"}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      {this.state.modalType === "remove"
                        ? "Are you sure ? All remaining members will be removed from the queue and will not be contacted."
                        : "Are you sure you want to close this campaign?  You will not be able to load new members or run this campaign again.  Also, any remaining calls will be canceled."}
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn-danger btn mar-r-20"
                      onClick={this.handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="primary-btn btn mar-r-20"
                      onClick={() => {
                        this.state.modalType === "remove"
                          ? this.handleRemoveQueuedMembers()
                          : this.handleCloseCampaign();
                        this.handleClose();
                      }}
                    >
                      Yes
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className="col-4">
                  <table className="table no-border">
                    <thead>
                      <tr>
                        <th colSpan="3">Disposition</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="label-text"
                          title="Call attempted and no answer"
                        >
                          Not Reached{" "}
                        </td>
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.unreachable
                                ? this.props.campaignDetails.unreachable
                                : ""}
                            </div>
                          </td>
                        )}
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.unreachableInPercent
                                ? this.props.campaignDetails
                                    .unreachableInPercent
                                : ""}
                            </div>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td
                          className="label-text"
                          title="Call connected message left on answering machine"
                        >
                          Left Message{" "}
                        </td>
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.leftMessage
                                ? this.props.campaignDetails.leftMessage
                                : ""}
                            </div>
                          </td>
                        )}
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.leftMessageInPercent
                                ? this.props.campaignDetails
                                    .leftMessageInPercent
                                : ""}
                            </div>{" "}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td
                          className="label-text"
                          title="Any member who successfully answered the call"
                        >
                          Call Connected
                        </td>
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.connected
                                ? this.props.campaignDetails.connected
                                : ""}
                            </div>
                          </td>
                        )}
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.connectedInPercent
                                ? this.props.campaignDetails.connectedInPercent
                                : ""}
                            </div>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-4">
                  <table className="table no-border">
                    <thead>
                      <tr>
                        <th colSpan="2">Outcome</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="label-text"
                          title="Member successfully completed the call "
                        >
                          Complete{" "}
                        </td>
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.completedCall
                                ? this.props.campaignDetails.completedCall
                                : ""}
                            </div>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td
                          className="label-text"
                          title="Member answered but did not make it to the final step of the call"
                        >
                          Incomplete{" "}
                        </td>
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.incompleteCall
                                ? this.props.campaignDetails.incompleteCall
                                : ""}
                            </div>{" "}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td
                          className="label-text"
                          title="Total number of members that were transferred to an agent"
                        >
                          Transferred{" "}
                        </td>
                        {!_.isEmpty(this.props.campaignDetails) && (
                          <td>
                            <div className="count-box">
                              {this.props.campaignDetails.transferred
                                ? this.props.campaignDetails.transferred
                                : ""}
                            </div>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="heading-row">
              <h2 className="main-heading">Upload History</h2>
            </div>

            <div className="table-outter" style={{ marginBottom: "20px" }}>
              <table className="table">
                <thead>
                  <th>
                    Filename{" "}
                    <span>
                      {this.state.sort ? (
                        <ArrowDropDownIcon
                          onClick={() => this.sortHandler(false)}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          onClick={() => this.sortHandler(true)}
                        />
                      )}
                    </span>
                  </th>
                  <th>
                    Uploaded By{" "}
                    <span>
                      {this.state.uploadedBy ? (
                        <ArrowDropDownIcon
                          onClick={() => this.sortUploadedByHandler(false)}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          onClick={() => this.sortUploadedByHandler(true)}
                        />
                      )}
                    </span>
                  </th>
                  <th>
                    Total Record Count{" "}
                    <span>
                      {this.state.totRecCount ? (
                        <ArrowDropDownIcon
                          onClick={() => this.sortTotalRecHandler(false)}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          onClick={() => this.sortTotalRecHandler(true)}
                        />
                      )}
                    </span>
                  </th>
                  <th>
                    Valid Record Count{" "}
                    <span>
                      {this.state.valRecCount ? (
                        <ArrowDropDownIcon
                          onClick={() => this.sortValidRecHandler(false)}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          onClick={() => this.sortValidRecHandler(true)}
                        />
                      )}
                    </span>
                  </th>
                  <th>
                    Rejected Record Count{" "}
                    <span>
                      {this.state.rejRecCount ? (
                        <ArrowDropDownIcon
                          onClick={() => this.sortRejectedRecHandler(false)}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          onClick={() => this.sortRejectedRecHandler(true)}
                        />
                      )}
                    </span>
                  </th>
                  <th>
                    Uploaded at{" "}
                    <span>
                      {this.state.uploadedAt ? (
                        <ArrowDropUpIcon
                          onClick={() => this.sortUploadedAtHandler(false)}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => this.sortUploadedAtHandler(true)}
                        />
                      )}
                    </span>
                  </th>
                  <th>Error</th>
                </thead>
                <tbody>
                  {uploadHistory.map((upload) => (
                    <tr>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.actualFileHandler(upload.campaignFileId)
                        }
                      >
                        {upload.originalFileName}
                      </td>

                      <td>{upload.uploadedBy}</td>
                      <td className="text-center">{upload.totalRecordCount}</td>
                      <td className="text-center">{upload.validRecordCount}</td>
                      <td className="text-center">
                        {upload.rejectedRecordCount}
                      </td>
                      <td>{upload.uploadedDateTime}</td>
                      <td className="text-center">
                        {upload.errorFilePath ? (
                          <span
                            onClick={() =>
                              this.errorFileHandler(upload.campaignFileId)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-exclamation-triangle error"></i>
                          </span>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))}
                  {_.isEmpty(this.props.uploadHistory) && (
                    <tr>
                      <td colSpan="4">
                        No files have been uploaded for this campaign. Please
                        use the "Upload Members" button above.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <TablePagination
                component="div"
                count={count}
                page={this.state.page_number}
                onPageChange={this.handleChangePage}
                rowsPerPage={this.state.page_size}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>

            <div className="heading-row">
              <h2 className="main-heading">Contact Schedule</h2>
            </div>

            <div className="container">
              {this.state.schedule.map((val, i) => {
                return (
                  <div className="scheduleContainer">
                    <div style={{ width: "150px" }}>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={val.status}
                            onChange={() => this.handleChangeToggle(i)}
                            name="checkedB"
                          />
                        }
                        label={val.weekDayName}
                      />
                    </div>

                    <TextField
                      id="time"
                      label="Start time"
                      type="time"
                      ampm={false}
                      onChange={(e) =>
                        this.handleChangeStartTime(i, e.target.value)
                      }
                      defaultValue={val.startTime}
                      className={styles.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />

                    <TextField
                      id="time"
                      label="End time"
                      type="time"
                      ampm={false}
                      onChange={(e) =>
                        this.handleChangeEndTime(i, e.target.value)
                      }
                      defaultValue={val.endTime}
                      className={styles.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                    {/* <div> {val.day}</div> */}
                  </div>
                );
              })}

              {this.state.schedule && this.state.schedule.length > 0 && (
                <button
                  className={
                    this.state.disabled
                      ? "secondary-btn btn mar-r-20 col-md-3 hovButtDisable"
                      : "secondary-btn btn mar-r-20 col-md-3 hovButt"
                  }
                  style={{
                    margin: "20px 0px",
                    backgroundColor: this.state.disabled ? "#f9f9f9" : "",
                    color: this.state.disabled ? "#000" : "",
                    cursor: this.state.disabled ? "default" : "",
                  }}
                  disabled={this.state.disabled}
                  onClick={this.state.disabled ? null : this.handlePostCampaign}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
