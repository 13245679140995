import React, { Component } from 'react';
import loader from '../../../assets/images/loader.gif';

class Loader extends Component {
    render(){
        return (
            <div className="loader-wrapper">
                <img src={loader} className="loader-image"  alt='loader' />
            </div>
        )
    }
}

export default Loader;